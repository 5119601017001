import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 style={{ textShadow: "2px 2px #dbcaca" }}>
          Laura and Chris Hester
        </h1>
        {/* <a
          className="App-link"
          onClick={() => alert("RSVP")}
          target="_blank"
          style={{ cursor: "pointer" }}
          rel="noopener noreferrer"
        >
          Coming Soon...
        </a> */}
      </header>
    </div>
  );
}

export default App;
